import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '@components/layout';
import SEO from '@components/seo';
import Banner from '@components/banner';

const IndexPage = data => (
  <Layout>
    <SEO title="Courses" keywords={[`gatsby`, `application`, `react`]} />
    <Banner
      BannerData={data.data.allContentfulPageBanner.edges}
      background={false}
    >
      <div className="bannerButtons">
        <a class="primButton" href="https://docs.google.com/forms/d/e/1FAIpQLSf0crqy6jcBWBdaDjzFULAqXHZO3NOZ2kfzJv_pl-8PIi-uWw/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">Attend survey</a>
      </div>
    </Banner>
    <div className="contactus">
      <h2>Don't be a stranger!</h2>
      <div className="mailto-button">
        <Link className="primButton blog" to={'/contact'}>
          Get in touch!
        </Link>
      </div>
    </div>
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query CoursesQuery {
    site {
      siteMetadata {
        url
      }
    }
    allContentfulPageBanner(filter: { page: { eq: "courses" } }) {
      edges {
        node {
          title
          subHeading
          page
          image {
            fluid(maxWidth: 1800) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  }
`;
